<template>
  <div class="article-list-block block">
    <div class="article-list-block block-wrapper header">
      <h2>{{ title }}</h2>
    </div>
    <div class="block-wrapper article-list-block-content" data-simplebar>
      <ul v-if="list && list.length > 0" class="article-list-block-list">
        <li class="article-list-block-item" @click="$emit('onClick', item.word)" :class="{active: selected === item.word}" v-for="(item, idx) in list" :key="`list-${idx}`">
          <span class="counter">{{idx + 1}}</span>
          <a :href="item.word" target="_blank" v-if="isLink">{{item.word}}</a>
          <span v-else>{{item.word}}</span>
          <span class="value">{{item.count}}</span>
        </li>
      </ul>
      <p v-else class="article-list-block-item">--</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleBlockList",
  props: ['title', 'list', 'isLink', 'onClick', 'selected']
}
</script>

<style scoped>

</style>
